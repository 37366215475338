@charset "utf-8";

html,body {
	height: 100%;
}

/*! contents - pc */


.error {
	height: 100%;
	#header-sub{
		display: none;
	} 
	#footer {
		display: none;
	}
	#container {
		height: calc(100% - 178px);
		.cnt-wrap {
			padding-top: 200px;
			text-align: center;
			.ico {
				display: inline-block;
				width: 109px;
				height: 97px;
				margin-bottom: 60px;
				background: url(../../images/common/ico_error.png) no-repeat;
				background-size: cover;
			}
			.tit {
				display: block;
				margin-bottom: 25px;
				font-family: $NotoSansM;
        font-size: $fontSizePc + 22;
			}
			.txt {
				color: #666;
				font-size: $fontSizePc + 2;
				line-height: 1.6;
			}
			.link {
				display: block;
				margin: 35px auto 0;
				width: 200px;
				padding: 20px 0;
				text-align: center;
				border-radius: 35px;
				background-color: #00abcd;
				box-sizing: border-box;
				.txt {
					color: #fff;
					font-size: $fontSizePc + 2;
				}
			}
		}
	}
}

.services {
	.nav, .pharmacy {
		display: none !important;
	}
	.title {
		margin: 70px 0 40px;
		text-align: center;
		span {
			font-size: $fontSizePc + 22;
			em {
				display: inline-block;
				margin-right: -10px;
				font-family: $NotoSansB;
			}
		}
	}
	.map-wrap {
		margin-bottom: get-vw(100px);
		img {
			width: 100%;
		}
	}
	.donga-link {
		display: block;
		width: 381px;
		margin: 0 auto 30px;
		text-align: center;
		border-radius: 40px;
		background-color: #eee;
		.txt {
			display: block;
			padding: 19px 0;
			font-family: $NotoSansM;
			font-size: $fontSizePc + 4;
		}
	}
	.link-wrap {
		margin-bottom: 18px;
		
	}
	.etc-link {
		display: flex;
		justify-content: space-between;
		a {
			display: block;
			width: 32.5%;
			padding: 18px 40px;
			color: #fff;
			border-radius: 10px;
			background-color: #00abcd;
			box-sizing: border-box;
			.logo {
				display: block;
				background-repeat: no-repeat;
				background-size: contain;
			}
			.tit {
				display: block;
				font-family: $NotoSansM;
				font-size: $fontSizePc + 6;
			}
			.txt {
				position: relative;
				display: inline-block;
				margin-top: 5px;
				padding-right: 18px;
				&:after {
					content:"";
					display: block;
					clear: both;
					position: absolute;
					right: 0;top: 4px;
					@include sprite-custom($pc-etc-arr1);
				}
			}
			&:nth-child(1) {
				.logo {
					width: 77px;height: 21px;
					background-image: url(../../images/common/etc_logo1.png);
				}
			}
			&:nth-child(2) {
				background-color: #e77261;
				.logo {
					width: 128px;height: 21px;
					background-image: url(../../images/common/etc_logo2.png);
				}
			}
			&:nth-child(3) {
				background-color: #85cc6f;
				.tit {
					padding: 7px 0;
					text-align: center;
				}
			}
		}
	}
}

// wide
@media (min-width: 1920px) {
	
}


@media screen and (min-width:1024px) and (max-width:1340px) {

}

// 태블릿
@media screen and (min-width:1024px) and (max-width:1200px) {

    
}