@charset "UTF-8";
/**
	* Computes a CSS calc function that betweens a value from
	* A to B over viewport-width A to viewport-width B.
	* Requires a media query to cap the value at B.
	* vw 단위를 활용하여 미디어쿼리 분기의 중간 상태값을
	* 계산해주는 SASS 함수.
	* 간격이나 폰트사이즈에 적용하면 자연스러운
	* 반응형 사이즈 처리가 가능 함.
	* 참고사이트 http://dohoons.com/blog/1364
	*/
/**
	* Read the article: http://sassbreak.com/viewport-relative-headings-with-sass
	* px 를 vw 로 자동으로 바꿔 줌.
	* max-breakpoint 를 설정하면 미디어쿼리 브레이크 포인트가 됨.
	* 그래서 미디어쿼리 브레이크 포인트에 맞춰 px 가 vw 로 변환됨.
	* 100% 보다 더 크게 적용 하고 싶으면 return 부분을 수정하면됨.
	* 참고사이트 http://sassbreak.com/viewport-relative-headings-with-sass/
	*/
@media (max-width: 1023px) {
  /*! contents - pc */
  #header-sub .header-content .nav {
    display: none; }
  #header-sub .header-content .mo-brand-menu .ico {
    display: none !important; }
  .error #header-sub {
    display: none; }
  .error #footer {
    display: none; }
  .error #container {
    margin-top: 0; }
    .error #container .cnt-wrap {
      padding: 26.66667vw 0;
      text-align: center; }
      .error #container .cnt-wrap .ico {
        display: inline-block;
        width: 19.46667vw;
        height: 17.33333vw;
        margin-bottom: 10.66667vw;
        background: url(../../images/common/ico_error.png) no-repeat;
        background-size: cover; }
      .error #container .cnt-wrap .tit {
        display: block;
        margin-bottom: 6.66667vw;
        font-family: "NotoSans-Medium";
        font-size: 6.66667vw;
        line-height: 1.4; }
      .error #container .cnt-wrap .txt {
        color: #666;
        font-size: 3.73333vw;
        line-height: 1.4; }
      .error #container .cnt-wrap .link {
        display: block;
        margin: 6.66667vw auto 0;
        width: 50.66667vw;
        padding: 4.66667vw 0;
        text-align: center;
        border-radius: 8vw;
        background-color: #00abcd;
        box-sizing: border-box; }
        .error #container .cnt-wrap .link .txt {
          color: #fff;
          font-size: 4.26667vw; }
  .services .title {
    padding: 0 0 10.66667vw;
    text-align: center; }
    .services .title span {
      font-size: 6.66667vw;
      line-height: 1.4;
      letter-spacing: -1px; }
      .services .title span em {
        margin-right: -1.33333vw;
        font-family: "NotoSans-Bold"; }
  .services .donga-link {
    display: block;
    width: 69.33333vw;
    margin: 0 auto 13.33333vw;
    text-align: center;
    border-radius: 6.66667vw;
    background-color: #eee; }
    .services .donga-link .txt {
      display: block;
      padding: 4.66667vw 0;
      font-family: "NotoSans-Medium";
      font-size: 4vw; }
    .services .donga-link.web-link {
      display: none; }
  .services .map-wrap {
    margin: 0 auto 10.66667vw;
    max-width: 670px; }
    .services .map-wrap img {
      width: 100%; }
  .services .link-wrap {
    margin-bottom: 10.66667vw; }
  .services .etc-link a {
    display: block;
    min-height: 15.73333vw;
    padding: 3.2vw 0;
    margin-bottom: 2.66667vw;
    color: #fff;
    text-align: center;
    border-radius: 2.13333vw;
    background-color: #00abcd;
    box-sizing: border-box; }
    .services .etc-link a .logo {
      display: block;
      margin: auto;
      background-repeat: no-repeat;
      background-size: contain; }
    .services .etc-link a .tit {
      display: block;
      margin: auto;
      font-family: "NotoSans-Medium";
      font-size: 5.06667vw; }
    .services .etc-link a .txt {
      position: relative;
      display: inline-block;
      margin-top: 2vw;
      padding-right: 3.46667vw;
      font-size: 3.46667vw; }
      .services .etc-link a .txt:after {
        content: "";
        display: block;
        clear: both;
        position: absolute;
        right: 0;
        top: 0.8vw;
        width: 1.6vw;
        height: 2.8vw;
        background: url(../../images/mo/etc_arr.png) no-repeat;
        background-size: contain; }
    .services .etc-link a:nth-child(1) .logo {
      width: 18.26667vw;
      height: 4.8vw;
      background-image: url(../../images/common/etc_logo1.png); }
    .services .etc-link a:nth-child(2) {
      background-color: #e77261; }
      .services .etc-link a:nth-child(2) .logo {
        width: 26.53333vw;
        height: 3.6vw;
        background-image: url(../../images/common/etc_logo2.png); }
    .services .etc-link a:nth-child(3) {
      padding: 4.93333vw;
      background-color: #85cc6f; } }

@media screen and (max-width: 1023px) and (min-width: 751px) and (max-width: 1023px) {
  .error #container .cnt-wrap {
    padding: 200px 0; }
    .error #container .cnt-wrap .ico {
      width: 146px;
      height: 130px;
      margin-bottom: 80px; }
    .error #container .cnt-wrap .tit {
      margin-bottom: 50px;
      font-size: 50px; }
    .error #container .cnt-wrap .txt {
      font-size: 28px; }
    .error #container .cnt-wrap .link {
      margin: 50px auto 0;
      width: 380px;
      padding: 35px 0;
      border-radius: 60px; }
      .error #container .cnt-wrap .link .txt {
        font-size: 32px; }
  .services .title {
    padding: 0 0 80px; }
    .services .title span {
      font-size: 50px; }
      .services .title span em {
        margin-right: -10px; }
  .services .donga-link {
    width: 520px;
    margin: 0 auto 100px;
    border-radius: 50px; }
    .services .donga-link .txt {
      padding: 35px 0;
      font-size: 30px; }
  .services .map-wrap {
    margin: 0 auto 80px; }
  .services .link-wrap {
    margin-bottom: 80px; }
  .services .etc-link a {
    min-height: 118px;
    padding: 24px 0;
    margin-bottom: 20px;
    border-radius: 16px; }
    .services .etc-link a .tit {
      font-size: 38px; }
    .services .etc-link a .txt {
      margin-top: 15px;
      padding-right: 26px;
      font-size: 26px; }
      .services .etc-link a .txt:after {
        top: 6px;
        width: 12px;
        height: 21px; }
    .services .etc-link a:nth-child(1) .logo {
      width: 137px;
      height: 36px; }
    .services .etc-link a:nth-child(2) .logo {
      width: 199px;
      height: 27px; } }

@media (min-width: 1024px) {
  html, body {
    height: 100%; }
  /*! contents - pc */
  .error {
    height: 100%; }
    .error #header-sub {
      display: none; }
    .error #footer {
      display: none; }
    .error #container {
      height: calc(100% - 178px); }
      .error #container .cnt-wrap {
        padding-top: 200px;
        text-align: center; }
        .error #container .cnt-wrap .ico {
          display: inline-block;
          width: 109px;
          height: 97px;
          margin-bottom: 60px;
          background: url(../../images/common/ico_error.png) no-repeat;
          background-size: cover; }
        .error #container .cnt-wrap .tit {
          display: block;
          margin-bottom: 25px;
          font-family: "NotoSans-Medium";
          font-size: 38px; }
        .error #container .cnt-wrap .txt {
          color: #666;
          font-size: 18px;
          line-height: 1.6; }
        .error #container .cnt-wrap .link {
          display: block;
          margin: 35px auto 0;
          width: 200px;
          padding: 20px 0;
          text-align: center;
          border-radius: 35px;
          background-color: #00abcd;
          box-sizing: border-box; }
          .error #container .cnt-wrap .link .txt {
            color: #fff;
            font-size: 18px; }
  .services .nav, .services .pharmacy {
    display: none !important; }
  .services .title {
    margin: 70px 0 40px;
    text-align: center; }
    .services .title span {
      font-size: 38px; }
      .services .title span em {
        display: inline-block;
        margin-right: -10px;
        font-family: "NotoSans-Bold"; }
  .services .map-wrap {
    margin-bottom: 5.20833vw; }
    .services .map-wrap img {
      width: 100%; }
  .services .donga-link {
    display: block;
    width: 381px;
    margin: 0 auto 30px;
    text-align: center;
    border-radius: 40px;
    background-color: #eee; }
    .services .donga-link .txt {
      display: block;
      padding: 19px 0;
      font-family: "NotoSans-Medium";
      font-size: 20px; }
  .services .link-wrap {
    margin-bottom: 18px; }
  .services .etc-link {
    display: flex;
    justify-content: space-between; }
    .services .etc-link a {
      display: block;
      width: 32.5%;
      padding: 18px 40px;
      color: #fff;
      border-radius: 10px;
      background-color: #00abcd;
      box-sizing: border-box; }
      .services .etc-link a .logo {
        display: block;
        background-repeat: no-repeat;
        background-size: contain; }
      .services .etc-link a .tit {
        display: block;
        font-family: "NotoSans-Medium";
        font-size: 22px; }
      .services .etc-link a .txt {
        position: relative;
        display: inline-block;
        margin-top: 5px;
        padding-right: 18px; }
        .services .etc-link a .txt:after {
          content: "";
          display: block;
          clear: both;
          position: absolute;
          right: 0;
          top: 4px;
          background-image: url(../../images/common/sprite_pc.png);
          background-position: -210px -100px;
          width: 8px;
          height: 12px;
          background-size: 219px 211px; }
      .services .etc-link a:nth-child(1) .logo {
        width: 77px;
        height: 21px;
        background-image: url(../../images/common/etc_logo1.png); }
      .services .etc-link a:nth-child(2) {
        background-color: #e77261; }
        .services .etc-link a:nth-child(2) .logo {
          width: 128px;
          height: 21px;
          background-image: url(../../images/common/etc_logo2.png); }
      .services .etc-link a:nth-child(3) {
        background-color: #85cc6f; }
        .services .etc-link a:nth-child(3) .tit {
          padding: 7px 0;
          text-align: center; } }
