@charset "utf-8";

/*! contents - pc */
#header-sub{
	.header-content{
		.nav {
			display: none;
		}
		.mo-brand-menu {
			.ico {
				display: none !important;
			}
		}
	}
}

.error {
	#header-sub{
		display: none;
	}
	#footer {
		display: none;
	}
	#container {
		margin-top: 0;
		.cnt-wrap {
			padding: get-vw-mo(200px) 0;
			text-align: center;
			.ico {
				display: inline-block;
				width: get-vw-mo(146px);
				height: get-vw-mo(130px);
				margin-bottom: get-vw-mo(80px);
				background: url(../../images/common/ico_error.png) no-repeat;
				background-size: cover;
			}
			.tit {
				display: block;
				margin-bottom: get-vw-mo(50px);
				font-family: $NotoSansM;
				font-size: get-vw-mo(50px);
				line-height: 1.4;
			}
			.txt {
				color: #666;
				font-size: get-vw-mo(28px);
				line-height: 1.4;
			}
			.link {
				display: block;
				margin: get-vw-mo(50px) auto 0;
				width: get-vw-mo(380px);
				padding: get-vw-mo(35px) 0;
				text-align: center;
				border-radius: get-vw-mo(60px);
				background-color: #00abcd;
				box-sizing: border-box;
				.txt {
					color: #fff;
					font-size: get-vw-mo(32px);
				}
			}
		}
	}
}

.services {
	.title {
		padding: 0 0 get-vw-mo(80px);
		text-align: center;
		span {
			font-size: get-vw-mo(50px);
			line-height: 1.4;
			letter-spacing: -1px;
			em {
				margin-right: get-vw-mo(-10px);
				font-family: $NotoSansB;
			}
		}
	}
	.donga-link {
		display: block;
		width: get-vw-mo(520px);
		margin: 0 auto get-vw-mo(100px);
		text-align: center;
		border-radius: get-vw-mo(50px);
		background-color: #eee;
		.txt {
			display: block;
			padding: get-vw-mo(35px) 0;
			font-family: $NotoSansM;
			font-size: get-vw-mo(30px);
		}
		&.web-link {
			display: none;
		}
	}
	.map-wrap {
		margin:0 auto get-vw-mo(80px);
		max-width: 670px;
		img {
			width: 100%;
		}
	}
	
	.link-wrap {
		margin-bottom: get-vw-mo(80px);
		
	}
	.etc-link {
		a {
			display: block;
			min-height: get-vw-mo(118px);
			padding: get-vw-mo(24px) 0;
			margin-bottom: get-vw-mo(20px);
			color: #fff;
			text-align: center;
			border-radius: get-vw-mo(16px);
			background-color: #00abcd;
			box-sizing: border-box;
			.logo {
				display: block;
				margin: auto;
				background-repeat: no-repeat;
				background-size: contain;
			}
			.tit {
				display: block;
				margin: auto;
				font-family: $NotoSansM;
				font-size: get-vw-mo(38px);
			}
			.txt {
				position: relative;
				display: inline-block;
				margin-top: get-vw-mo(15px);
				padding-right: get-vw-mo(26px);
				font-size: get-vw-mo(26px);
				&:after {
					content:"";
					display: block;
					clear: both;
					position: absolute;
					right: 0;top: get-vw-mo(6px);
					width: get-vw-mo(12px);height: get-vw-mo(21px);
					background: url(../../images/mo/etc_arr.png) no-repeat;
					background-size: contain;
				}
			}
			&:nth-child(1) {
				.logo {
					width: get-vw-mo(137px);height: get-vw-mo(36px);
					background-image: url(../../images/common/etc_logo1.png);
				}
			}
			&:nth-child(2) {
				background-color: #e77261;
				.logo {
					width: get-vw-mo(199px);height: get-vw-mo(27px);
					background-image: url(../../images/common/etc_logo2.png);
				}
			}
			&:nth-child(3) {
				padding: get-vw-mo(37px);
				background-color: #85cc6f;
			}
		}
	}
}

// 태블릿
@media screen and (min-width: 751px) and (max-width: 1023px) {
	.error {
		#container {
			.cnt-wrap {
				padding: 200px 0;
				.ico {
					width: 146px;
					height: 130px;
					margin-bottom: 80px;
				}
				.tit {
					margin-bottom: 50px;
					font-size: 50px;
				}
				.txt {
					font-size: 28px;
				}
				.link {
					margin: 50px auto 0;
					width: 380px;
					padding: 35px 0;
					border-radius: 60px;
					.txt {
						font-size: 32px;
					}
				}
			}
		}
	}
	.services {
		.title {
			padding: 0 0 80px;
			span {
				font-size: 50px;
				em {
					margin-right: -10px;
				}
			}
		}
		.donga-link {
			width:520px;
			margin: 0 auto 100px;
			border-radius: 50px;
			.txt {
				padding: 35px 0;
				font-size: 30px;
			}
		}
		.map-wrap {
			margin:0 auto 80px;
		}
		
		.link-wrap {
			margin-bottom: 80px;
			
		}
		.etc-link {
			a {
				min-height: 118px;
				padding: 24px 0;
				margin-bottom: 20px;
				border-radius: 16px;
				.tit {
					font-size: 38px;
				}
				.txt {
					margin-top: 15px;
					padding-right: 26px;
					font-size: 26px;
					&:after {
						top: 6px;
						width: 12px;height:21px;
					}
				}
				&:nth-child(1) {
					.logo {
						width: 137px;height:36px;
					}
				}
				&:nth-child(2) {
					.logo {
						width: 199px;height: 27px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 414px) {

}